import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React, { useState } from "react";

const Image = dynamic(
	() => import("next/image"),
);
// import {
// 	toBase64, shimmerImgLoader,
// } from "Helpers/utils";

const ImageFallbackWithNext = ({
	src, alt, outOfStock, objectFit, ...props
}) => {

	const fallBackSrc = `${process.env.NEXT_PUBLIC_S3_URL}/placeholder_image_not_found.png`;
	const [imageError, setImageError] = useState(false);

	return (
		<>
			<Image
				className={`img-fallback ${outOfStock ? "OOS" : ""}`}
				src={imageError ? fallBackSrc : src}
				alt={alt}
				objectFit={objectFit}
				layout="fill"
				onError={() => setImageError(true)}
				{...props}
			/>
			<style jsx>
				{`
            :global(.OOS) {
                opacity: 0.5;
            }
            `}
			</style>
		</>
	);

};

ImageFallbackWithNext.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	outOfStock: PropTypes.bool,
	objectFit: PropTypes.string,
};

ImageFallbackWithNext.defaultProps = {
	outOfStock: false,
	objectFit: "contain",
};

const conditionalMemo = (
	prevState,
	nextState,
) => JSON.stringify(prevState) === JSON.stringify(nextState);
export default React.memo(ImageFallbackWithNext, conditionalMemo);
